import React, {useRef} from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css'
import { FaShieldAlt, FaRegStar, FaKey, FaEnvelope, FaPhone, FaFacebook, FaWarehouse } from 'react-icons/fa'
import logo from '../images/logo.svg';
import palletlift from '../images/palletlift.jpg'
import gang from '../images/gang.jpg'
import rum1 from '../images/rum1.jpg'
import omos from '../images/omos.jpg'
import facade from '../images/facade_ny2.jpeg'
import facade3 from '../images/facade3.jpg'
import erhvervPort from '../images/mellem_rum_inde.jpeg'
import halInde1 from '../images/hal_ny_1.jpeg'
import halInde2 from '../images/hal_inde_2.jpeg'
import halUde from '../images/hal_ude.jpg'

//Components
import Header from "../components/Header";
import TextCol from '../components/TextCol';
import StorageOptions from '../components/StorageOptions';
import Footer from '../components/Footer';
import Slider from '../components/Slider';

const isBrowser = typeof window !== "undefined"

const IndexPage = () => {
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    
    const contactClick = () => {
      contactRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const aboutClick = () => {
      aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    }
    const sliderImages = [facade3, halUde, halInde1, halInde2, gang, facade, erhvervPort]

    if (isBrowser) {
        return (
            <>
            <Helmet>
              <title>Forside | Skovs Lager</title>
            </Helmet>
                <Header handleClickContact={contactClick} handleClickAbout={aboutClick} showContactAndAbout={true}/>
                <div className='flex flex-col min-h-screen xl:flex-row'>
                <TextCol background='bg2' title='BILLIGT LAGER I NÆRHEDEN AF KØGE' 
                        bgimage='facade'
                        text={(<>
                        <br />
                        <p className='text-2xl'>
                          Leder du efter lagerplads i Tureby- og Herfølge-området? Lad Skovs Lager hjælpe dig med at finde den bedste løsning i en af vores lagerløsninger.
                        </p>
                        <br />
                        <p className='text-lg'>
                        På en gård tæt på syd-motorvejens afkørsel 34 Herfølge, syd for Køge finder du Skovs Lager. 
                        Her tilbyder vi opbevaring i nyrenoverede staldbygninger, der er isolerede og lettere opvarmet med gulvvarme.
                        </p>
                        <br />
                        <p className='text-lg'>
                        Ejer Frits Skov Hansen bor på gården, og vil ud over at stå for den daglige drift og vedligehold være til rådighed det meste af tiden og kan derfor holde opsyn med lageret. 
                        Derudover er lagerbygningerne videoovervåget og udstyret med elektroniske låse hvilket giver dig adgang til lageret 24/7 samtidig med at uvedkommende er nægtet adgang. Det sikrer en tryg opbevaring hos os. 
                        </p>
                        <br />
                        <p className='text-lg'>
                        Vores rum varierer i størrelse fra 2,2 til 600 m2 med loftshøjder fra 2,40 til 6,40 m, 
                        så uanset om du har brug for plads til store eller små varer, 
                        har vi den rette løsning til dig.
                        Til erhverv tilbyder vi at indrette rummene efter ønskede størrelse med egen port. Alle lagerrum til erhverv har gode tilgangsforhold for lastbiler. 
                        </p>
                        {/* <p>
                          Lad os hjælpe dig med at finde den bedste løsning i en af vores opbevaringspladser.
                        </p>
                        <p className='text-xl'>
                        Skovs lager ligger på en gård tæt på syd motorvejens afkørsel 34 Herfølge,  10 km. syd for Køge. Lageret er i renoverede staldbygninger som er isolerede og opvarmes med gulvvarme fra et træfyr.
                        Jeg bor selv på gården, og vil stå til rådighed det meste af tiden og kan derfor holde opsyn med lageret hver dag. Lager området vil desuden blive overvåget af video camera.
                        Vi kan tilbyde opbevaring for private i rum fra 2,2 – 16 m2 med loftshøjde 2,40 m.  Til erhverv 16- 250 m2 med loftshøjde 2,6 m. Til erhverv  tilbyder vi at indrette rummene efter ønskede størrelse med egen port.
                        </p>
                        <br />
                        <p>
                            Skovs Lager er en ny lagervirksomhed beliggende i et nedlagt landbrug. 
                            Det betyder at vi løbende laver lagerrum i stand, 
                            hvilket giver dig en unik mulighed for at få et lagerrum der er skræddersyet til dine behov.
                            </p> */}
                          <br />
                          <br />
                          <h2 className='text-3xl'>Kontakt</h2>
                          <p className='text-lg'>
                            Ring til os på <a href='tel:+4520493101'>20 49 31 01</a> eller skriv en email til <a href='mailto:fsh@skovslager.dk'>fsh@skovslager.dk</a>, for at høre nærmere om dine muligheder for at få et skræddersyet lagerrum.
                          </p>
                          <div className='flex flex-row justify-between lg:justify-evenly p-20'>
                            <a href='mailto:fsh@skovslager.dk' className='hover:transition-all hover:text-yellow-300 hover:-translate-y-1 duration-150 ease-in-out'>
                              <FaEnvelope size='55px'/>
                            </a>
                            <a href='tel:+4520493101' className='hover:transition-all hover:text-green-800 hover:-translate-y-1 duration-150 ease-in-out'>
                              <FaPhone size='55px'/>
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100091863780926' className='hover:transition-all hover:text-fbblue hover:-translate-y-1 duration-150 ease-in-out'>
                            <FaFacebook size='55px' />
                          </a>
                            <br />
                          </div>
                          </>)} />
                  <img src={facade} alt='vores logo' className='hidden w-0 object-cover xl:w-1/2 xl:block' />
                   <Slider images={sliderImages}/>
                </div>
                <StorageOptions title='Lagerløsninger' text='Se vores udbud af lagerrum herunder'/>
                {/* <div className="hidden lg:flex">
                  <div className='w-full text-center text-secondary bg-slate-600 p-20 lg:w-1/3'>
                    <FaShieldAlt className='w-full h-1/2 p-10' />
                    <br /> <br />
                    <h3 className='text-2xl text-tertiary'>Opbevar dine genstande sikkert og nemt</h3>
                  </div>
                  <div className='w-full text-center text-secondary bg-slate-700 p-20 lg:w-1/3'>
                    <FaRegStar className='w-full h-1/2 p-10'/>
                    <br /> <br />
                    <h3 className='text-2xl text-tertiary'>Nye, topmoderne lagerrum</h3>
                  </div>
                  <div className='w-full text-center text-secondary bg-slate-800 p-20 lg:w-1/3'>
                    <FaKey className='w-full h-1/2 p-10'/>
                    <br /> <br />
                    <h3 className='text-2xl text-tertiary'>Ubegrænset adgang 24/7</h3>
                  </div>
                </div> */}
                <div className='flex flex-col-reverse items-center py-10 bg-secondary lg:flex-row lg:justify-center'>
                <div className='flex w-full justify-center pb-2 lg:w-1/2'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4384.114153694433!2d12.101356368901136!3d55.39615013202094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652ef2ea76b123b%3A0xf469825c0cdb114b!2sSkovs%20Lager!5e1!3m2!1sen!2sdk!4v1680852528273!5m2!1sen!2sdk" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='p-4 text-center'>
                  <h2 className='text-4xl pb-2'>Find vej</h2>
                  <p className='hidden py-2 lg:flex'>Vi befinder os tæt på E47 afkørsel 34 Herfølge, blot 10 km fra Køge. </p>
                </div>
                </div>
                <div className='flex flex-row' ref={aboutRef}>
                <img src={rum1} alt='vores logo' className='hidden object-cover lg:w-1/2 lg:block' />
                <TextCol bgimage='about' title='Hvem er vi?'
                        text={(<><p className='text-lg'>
                          Skovs Lager er grundlagt i 2023 af tidligere griseavler Frits Skov Hansen efter at grisene er skiftet ud med tomme stalde. 
                          Frits så muligheden for at omdanne disse mange ubenyttede kvadratmeter til opbevaring, og 
                          det tog ikke lang tid fra de første stalde blev tømt til renoveringen begyndte.
                        </p>
                        <br />
                        <p className='text-lg'>
                            Ved at vælge Skovs Lager som din opbevaringspartner, er du sikret et nyrenoveret lagerrum
                            der passer lige til dine behov. Vores lagerrum er sikret efter høje brandstandarder.
                            </p>
                          <br />
                          <h2 className='text-xl font-bold' ref={contactRef}>Kontakt</h2>
                          <p className='text-lg'>
                          Ring til os på <a href='tel:+4520493101'>20 49 31 01</a> eller skriv en email til <a href='mailto:fsh@skovslager.dk'>fsh@skovslager.dk</a>, for at høre nærmere om dine muligheder for at få et skræddersyet lagerrum.
                          </p>
                          <div className='flex flex-row justify-evenly p-20'>
                            <a href='mailto:fsh@skovslager.dk' className='hover:transition-all hover:text-yellow-300 hover:-translate-y-1 duration-150 ease-in-out'>
                              <FaEnvelope size='55px'/>
                            </a>
                            <a href='tel:+4520493101' className='hover:transition-all hover:text-green-800 hover:-translate-y-1 duration-150 ease-in-out'>
                              <FaPhone size='55px'/>
                            </a>
                            <br />
                          </div>
                          <h2 className='text-xl font-bold'>Find os på Facebook</h2>
                          <div className='flex justify-center pt-20'>
                          <a href='https://www.facebook.com/profile.php?id=100091863780926' className='hover:transition-all hover:text-fbblue hover:-translate-y-1 duration-150 ease-in-out'>
                            <FaFacebook size='55px' />
                          </a>
                          </div>
                          <br />
                          <h2 className='text-xl font-bold'>Find os også på LokalePortalen</h2>
                          <div className='flex justify-center pt-20'>
                          <a href='https://www.lokaleportalen.dk/virksomhed/skovs-lager/252338' className='hover:transition-all hover:text-fbblue hover:-translate-y-1 duration-150 ease-in-out' target='_blank' rel='noopener noreferrer'>
                            <img src='https://www.lokaleportalen.dk/media/yl4fpxwb/lokaleportalen-sevoreslokaler.png' width='361' height='141' alt='Lokaleportalen - se vores lokaler' />
                            {/* <FaWarehouse size='55px' /> */}
                            </a>
                          </div>
                          </>)} />
                </div>
                <Footer />
            </>
        )

    }
    else {
        return
    }
}

export default IndexPage;